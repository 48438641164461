import React, { useState } from 'react';
import { ArrowRight, BarChart, Laptop, FileText, X } from 'lucide-react';



const MainPage = () => {

    const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    company: '',
    assets: '',
    description: ''
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.email) newErrors.email = 'Email is required';
    if (!formData.phone) newErrors.phone = 'Phone is required';
    if (!formData.company) newErrors.company = 'Company is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await fetch('/api/send-email', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(formData)
        });
        if (response.ok) {
          alert('Thank you for your interest. We will contact you soon!');
          setShowModal(false);
          setFormData({
            name: '',
            email: '',
            phone: '',
            company: '',
            assets: '',
            description: ''
          });
        } else {
          alert('An error occurred. Please try again later.');
        }
      } catch (error) {
        console.error('Error:', error);
        alert('An error occurred. Please try again later.');
      }
    }
  };
  return (
    <div className="bg-white text-gray-800">
      {/* Navigation */}
      <nav className="bg-teal-600 p-4">
        <div className="container mx-auto flex justify-between items-center">
          <div className="text-white text-2xl font-bold">SecureFlowSense</div>
          <div className="space-x-4">
            <a href="#features" className="text-white hover:text-teal-200">Features</a>
            <a href="#case-studies" className="text-white hover:text-teal-200">Case Studies</a>
            <a href="#demo" className="text-white hover:text-teal-200">Request Demo</a>
          </div>
        </div>
      </nav>

      {/* Hero Section */}
      <header className="bg-teal-500 text-white py-20">
        <div className="container mx-auto text-center px-4">
          <h1 className="text-4xl md:text-5xl font-bold mb-4">Tailored Asset Management Solutions</h1>
          <p className="text-xl mb-8">Powerful, customizable, and scalable for any industry</p>
          <button 
            onClick={() => setShowModal(true)}
            className="bg-white text-teal-600 px-6 py-3 rounded-full font-bold hover:bg-teal-100 transition duration-300 flex items-center mx-auto"
          >
            Request a Demo <ArrowRight className="ml-2" />
          </button>
        </div>
      </header>

      {/* Features Section */}
      <section id="features" className="py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Key Features</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <FeatureCard
              icon={<BarChart className="w-12 h-12 text-teal-500" />}
              title="Comprehensive Dashboard"
              description="Get a bird's eye view of all your assets with our intuitive dashboard."
            />
            <FeatureCard
              icon={<Laptop className="w-12 h-12 text-teal-500" />}
              title="Asset Tracking"
              description="Track and manage all your assets in real-time across multiple locations."
            />
            <FeatureCard
              icon={<FileText className="w-12 h-12 text-teal-500" />}
              title="Custom Reporting"
              description="Generate detailed reports tailored to your specific needs and requirements."
            />
          </div>
        </div>
      </section>

      {/* Case Studies Section */}
      <section id="case-studies" className="bg-gray-100 py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">More Details Coming Soon</h2>
        </div>
      </section>

      {/* CTA Section */}
      <section id="demo" className="bg-teal-600 text-white py-20">
        <div className="container mx-auto text-center px-4">
          <h2 className="text-3xl font-bold mb-4">Ready to transform your asset management?</h2>
          <p className="text-xl mb-8">Contact our sales team for a personalized demo and consultation.</p>

          <button 
            onClick={() => setShowModal(true)}
            className="bg-white text-teal-600 px-6 py-3 rounded-full font-bold hover:bg-teal-100 transition duration-300 flex items-center mx-auto"
          >
            Request a Demo <ArrowRight className="ml-2" />
          </button>
        </div>
      </section>

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-8 rounded-lg max-w-md w-full">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl font-bold">Request a Demo</h2>
              <button onClick={() => setShowModal(false)} className="text-gray-500 hover:text-gray-700">
                <X size={24} />
              </button>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label htmlFor="name" className="block mb-1">Name *</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                  required
                />
                {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name}</p>}
              </div>
              <div className="mb-4">
                <label htmlFor="email" className="block mb-1">Email *</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                  required
                />
                {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
              </div>
              <div className="mb-4">
                <label htmlFor="phone" className="block mb-1">Phone *</label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                  required
                />
                {errors.phone && <p className="text-red-500 text-sm mt-1">{errors.phone}</p>}
              </div>
              <div className="mb-4">
                <label htmlFor="company" className="block mb-1">Company *</label>
                <input
                  type="text"
                  id="company"
                  name="company"
                  value={formData.company}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                  required
                />
                {errors.company && <p className="text-red-500 text-sm mt-1">{errors.company}</p>}
              </div>
              <div className="mb-4">
                <label htmlFor="assets" className="block mb-1">Number of Assets</label>
                <input
                  type="number"
                  id="assets"
                  name="assets"
                  value={formData.assets}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="description" className="block mb-1">Description</label>
                <textarea
                  id="description"
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                  rows="3"
                ></textarea>
              </div>
              <button type="submit" className="bg-teal-600 text-white px-4 py-2 rounded hover:bg-teal-700 transition duration-300">
                Submit
              </button>
            </form>
          </div>
        </div>
      )}

      {/* Footer */}
      <footer className="bg-gray-800 text-white py-8">
        <div className="container mx-auto text-center">
          <p>&copy; 2024 SecureFlowSense. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

const FeatureCard = ({ icon, title, description }) => (
  <div className="bg-white p-6 rounded-lg shadow-md text-center">
    <div className="mb-4 flex justify-center">{icon}</div>
    <h3 className="text-xl font-bold mb-2">{title}</h3>
    <p>{description}</p>
  </div>
);

const CaseStudyCard = ({ title, description }) => (
  <div className="bg-white p-6 rounded-lg shadow-md">
    <h3 className="text-xl font-bold mb-2">{title}</h3>
    <p>{description}</p>
    <a href="#" className="text-teal-500 mt-4 inline-block hover:underline">Read more</a>
  </div>
);

export default MainPage;